<template>
  <common-modal
    ref="commonModal"
    title="Xử lý hồ sơ"
    size="xl"
    @handle-ok="onSave"
    @reset-form="resetForm"
  >
    <b-form-group
      label="Cập nhật thông tin trả kết quả"
    >
      <b-form-textarea
        v-model="comment"
        placeholder="Nhập ..."
      />
    </b-form-group>
    <b-form-group
      label="Thông tin hồ sơ cần xử lý"
    >
      <good-table
        ref="baoCao"
        :columns="getColumns()"
        :rows="selectedItem"
        :pagination="false"
        :selectable="false"
        @delete-item="deleteItem"
      >
        <template
          slot="custom-row"
          slot-scope="props"
        >
          <validation-provider
            v-if="props.props.column.field === 'buocXuLy'"
            v-slot="{ errors }"
            :key="`buocXuLy${props.props.index}`"
            :rules="{ required: true }"
            :name="`buocXuLy${props.props.index}`"
          >
            <treeselect
              v-if="selectedItem[props.props.index]"
              id="buocXuLy"
              v-model="selectedItem[props.props.index].buocXuLy"
              v-format-v-select
              :options="props.props.row.danhSachBuocXuLy"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              :clearable="false"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder="Chọn bước xử lý"
              :normalizer="normalizer"
              :clear-on-select="true"
              @input="layNguoiXuLy(props.props.row); setBuocXuLy($event, props.props.index);"
            />
            <span class="label-noti-validate">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider
            v-else-if="props.props.column.field === 'tenLoaiBaoCao'"
            v-slot="{ errors }"
            :key="`nguoiXuly${props.props.index}`"
            :rules="{ required: true }"
            :name="`nguoiXuly${props.props.index}`"
          >
            <treeselect
              v-if="selectedItem[props.props.index]"
              id="nguoiXuLy"
              v-model="selectedItem[props.props.index].tenLoaiBaoCao"
              v-format-v-select
              :options="props.props.row.danhSachTenLoaiBaoCao"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder="Chọn người xử lý"
              :normalizer="normalizer"
              :clear-on-select="true"
              :match-keys="['name']"
            />
            <span class="label-noti-validate">{{ errors[0] }}</span>
          </validation-provider>
        </template>
        <template
          slot="actions"
          slot-scope="props"
        >
          <feather-icon
            v-if="selectedItem.length > 1"
            v-b-tooltip.hover.v-secondary
            title="Xóa"
            icon="Trash2Icon"
            size="15"
            class="icon cursor-pointer"
            @click="deleteItem(props.props.row.id)"
          />
          <span v-else>
            <div />
          </span>
        </template>
      </good-table>
    </b-form-group>
    <div
      slot="footer"
    >
      <b-button
        v-if="checkXacThucKySo"
        variant="primary"
        size="sm"
        class="mr-1"
        @click="onXacNhanKySo()"
      >
        Xác nhận
      </b-button>
      <b-button
        v-else
        variant="primary"
        type="submit"
        size="sm"
        class="mr-1"
        @click="onSave"
      >
        Lưu
      </b-button>
      <b-button
        variant="danger"
        size="sm"
        @click="$refs.commonModal.hideModal()"
      >
        Đóng
      </b-button>
    </div>
  </common-modal>
</template>

<script>
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import GoodTable from '@/components/GoodTable.vue'
import {
  VBTooltip,
  BFormTextarea,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import END_POINTS from '@/api/endpoints'
import { vgca_sign_msg } from '@/utils/vgcaplugin'

export default {
  name: 'XuLyHoSo',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormTextarea,
    BFormGroup,
    BButton,
    GoodTable,
    CommonModal,
  },
  data() {
    return {
      comment: '',
      checkXacThucKySo: false,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center vertical-align-middle',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Mã biên nhận',
          field: 'maBienNhan',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          width: '200px',
        },
        {
          label: 'Tên thủ tục hành chính',
          field: 'tenThuTucHanhChinh',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          width: 'auto',
        },
        {
          label: 'Bước xử lý',
          field: 'buocXuLy',
          thClass: 'text-center',
          width: '250px',
        },
        {
          label: 'Người nhận xử lý tiếp theo',
          field: 'tenLoaiBaoCao',
          thClass: 'text-center',
          width: '300px',
        },
        {
          label: 'Thao tác',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle text-center',
          field: 'actions',
          width: '100px',
        },
      ],
      selectedItem: [],
      type: null,
      isXacThuc: false,
    }
  },
  methods: {
    getColumns() {
      if (this.selectedItem && this.selectedItem.length === 1) {
        return this.type === 'TRA_KET_QUA' ? this.columns.filter(item => item.field !== 'buocXuLy' && item.field !== 'tenLoaiBaoCao' && item.field !== 'actions') : this.columns.filter(item => item.field !== 'actions')
      }
      return this.type === 'TRA_KET_QUA' ? this.columns.filter(item => item.field !== 'buocXuLy' && item.field !== 'tenLoaiBaoCao') : this.columns
    },
    onSave() {
      this.$refs.commonModal.$refs.commonRules.validate().then(success => {
        if (success) {
          const payload = this.selectedItem.map(item => ({
            processId: item.id,
            command: item.buocXuLy,
            allowIdentityId: item.tenLoaiBaoCao,
            comment: this.comment,
            type: item.type,
            xacThucKySo: item.xacThucKySo,
            signature: item.signature,
          }))
          this.$axios.post(END_POINTS.WORKFLOW_CUSTOM.XU_LY_NHIEU_HO_SO, payload, false).then(response => {
            if (response.data.code === 200) {
              this.$refs.commonModal.hideModal()
              this.$toast.success(response.data.message)
              if (response.data.data) {
                response.data.data.forEach(item => {
                  if (!item.wasExecuted) {
                    this.$toast.error(item.message)
                  }
                })
              }
              this.$emit('reload-page')
            } else {
              this.$toast.error(response.data.message)
            }
          })
        }
      })
    },
    resetForm() {},
    deleteItem(id) {
      this.selectedItem = this.selectedItem.filter(item => item.id !== id)
    },
    layDanhSachBuocXuLy() {
      const payload = {
        dataHoSoId: this.selectedItem.map(item => item.id),
      }
      this.$axios.post(END_POINTS.WORKFLOW_CUSTOM.DANH_SACH_XU_LY, payload, false).then(response => {
        if (response.data.code === 200) {
          const selectedItemLength = this.selectedItem.length
          response.data.data.forEach(comand => {
            for (let i = 0; i < selectedItemLength; i += 1) {
              if (this.selectedItem[i].id === comand.hoSoId) {
                this.selectedItem[i].danhSachBuocXuLy = comand.dataCommand
                if (this.selectedItem[i].danhSachBuocXuLy.length === 1) {
                  this.selectedItem[i].buocXuLy = this.selectedItem[i].danhSachBuocXuLy[0].commandName
                }
              }
            }
          })
        }
      })
    },
    show(items) {
      this.$refs.commonModal.showModal()
      this.selectedItem = items.map(item => ({
        id: item.id,
        maBienNhan: item.code,
        buocXuLy: null,
        tenLoaiBaoCao: null,
        tenThuTucHanhChinh: item.tenThuTucHanhChinh,
        danhSachBuocXuLy: [],
        danhSachTenLoaiBaoCao: [],
      }))
      this.layDanhSachBuocXuLy()
    },
    normalizer(node) {
      return {
        id: node.commandName || node.userId,
        label: node.commandName || node.fullName,
        name: (node.commandName || node.fullName) + this.$removeDiacritical(node.commandName || node.fullName),
      }
    },
    layNguoiXuLy(row) {
      this.$nextTick(() => {
        const payload = {
          command: this.selectedItem[row.originalIndex].buocXuLy,
        }
        this.$axios.get(`${END_POINTS.WORKFLOW_CUSTOM.DANH_SACH_NGUOI_XU_LY}/${row.id}`, payload, false).then(response => {
          if (response.data.code === 200) {
            this.selectedItem[row.originalIndex].danhSachTenLoaiBaoCao = response.data.data
            if (response.data.data.length === 1) {
              this.selectedItem[row.originalIndex].tenLoaiBaoCao = response.data.data[0].userId
            }
          }
        })
      })
    },
    setBuocXuLy(value, index) {
      const result = this.selectedItem[index].danhSachBuocXuLy.find(item => item.commandName.indexOf(value) >= 0)
      this.selectedItem[index].type = result ? result.type : null
      this.selectedItem[index].xacThucKySo = result ? result.xacThucKySo : null
      this.type = result ? result.type : null
      if (result && result.xacThucKySo) {
        this.isXacThuc = true
      }
      const arr = this.selectedItem.map(item => item.xacThucKySo)
      const resultXacNhan = arr.find(item => item)
      if (!resultXacNhan) {
        this.isXacThuc = false
        this.checkXacThucKySo = false
      } else {
        this.checkXacThucKySo = true
      }
      if (!value) {
        this.selectedItem[index].tenLoaiBaoCao = null
      }
    },
    setNguoiXuLy(value, index) {
      this.selectedItem[index].tenLoaiBaoCao = value
    },
    onXacNhanKySo(sender = '_Sign') {
      const SignCallBack = async (sender1, rv) => {
        const received = JSON.parse(rv)
        if (received.Status === 0) {
          this.isXacThuc = false
          this.selectedItem = this.selectedItem.map(item => {
            if (item.xacThucKySo) {
              return {
                ...item,
                signature: received.Signature,
              }
            }
            return item
          })
          this.$refs.commonModal.$refs.commonRules.validate().then(success => {
            if (success) {
              this.onSave()
            }
          })
        }
      }
      // eslint-disable-next-line global-require
      const crypto = require('crypto')
      // hash from file hashed joim text hashed
      const hash = crypto.createHash('sha256').update('1').digest('base64')
      const hashValue = hash
      const hashAlg = 'SHA256'
      const prms = {}
      prms.HashValue = hashValue
      prms.HashAlg = hashAlg
      const jsonPrms = JSON.stringify(prms)
      vgca_sign_msg(sender, jsonPrms, SignCallBack)
    },
  },
}
</script>

<style scoped>

</style>
