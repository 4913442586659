<template>
  <div>
    <div class="custom-grid-xl-container">
      <b-form-group
        id="input-group-1"
        label="Lĩnh vực"
        label-for="input-1"
        class="grap-4"
      >
        <treeselect
          id="linhVuc"
          ref="tenLinhVuc"
          v-model="form.dataLinhVucId"
          v-format-v-select
          :default-options="lvCbx"
          placeholder="Chọn lĩnh vực"
          :limit="0"
          multiple
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          loading-text="Đang tìm kiếm"
          :normalizer="normalizer"
          :match-keys="['label', 'label2']"
          :load-options="onQueryChange"
          :async="true"
          :clear-on-select="true"
          @select="$refs.tenLinhVuc.resetSearchQuery()"
        >
          <label
            slot="option-label"
            slot-scope="{ node, shouldShowCount, count, labelClassName }"
            :class="labelClassName"
            :title="node.label"
          >
            {{ node.label }}
          </label>
        </treeselect>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Ngày tiếp nhận"
        label-for="input-1"
        class="grap-4"
      >
        <date-picker
          v-model="form.thoiGianTiepNhan"
          format="DD/MM/YYYY"
          placeholder="Từ ngày"
          range
          class="h-100 w-100"
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Ngày hẹn trả"
        label-for="input-1"
        class="grap-4"
      >
        <date-picker
          v-model="form.thoiGianHenTra"
          format="DD/MM/YYYY"
          range
          placeholder="Từ ngày"
          class="h-100 w-100"
        />
      </b-form-group>
    </div>
    <div class="d-sm-flex justify-content-start">
      <button
        class="compact ui btn-primary button"
        @click="onAdvancedSearch"
      >
        Tìm kiếm
      </button>
      <button
        class="compact ui btn-primary button"
        @click="resetForm"
      >
        Làm mới
      </button>
    </div>
  </div>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import END_POINTS from '@/api/endpoints'
import { removeDiacritical, formatDateRequest } from '@/utils/index'
import _debounce from 'lodash/debounce'
import _isEmpty from 'lodash/isEmpty'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormGroup,
    DatePicker,
  },
  data() {
    return {
      lvCbx: [],
      tthcCbx: [],
      form: {
        dataLinhVucId: [],
        thoiGianTiepNhan: null,
        thoiGianHenTra: null,
      },
    }
  },
  created() {
    this.$axios.get(END_POINTS.LINH_VUC.COMBOBOX).then(res => {
      if (res.data?.succeeded) {
        this.lvCbx = res.data.data
      }
    })
    if (!_isEmpty(this.$route.query)) {
      this.form.thoiGianTiepNhan = [new Date(this.$route.query.tuNgay), new Date(this.$route.query.denNgay)]
    }
  },
  methods: {
    onQueryChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQueryChange: _debounce(async function (query, callback) {
      const response = this.lvCbx.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        label2: removeDiacritical(node.name),
      }
    },
    onAdvancedSearch() {
      this.$emit('advanced-search')
    },
    getFormFilter() {
      return {
        dataLinhVucId: this.form.dataLinhVucId,
        thoiGianTiepNhanTu: formatDateRequest(this.form.thoiGianTiepNhan && this.form.thoiGianTiepNhan[0]),
        thoiGianTiepNhanDen: formatDateRequest(this.form.thoiGianTiepNhan && this.form.thoiGianTiepNhan[1]),
        thoiGianHenTraDen: formatDateRequest(this.form.thoiGianHenTra && this.form.thoiGianHenTra[0]),
        thoiGianHenTraTu: formatDateRequest(this.form.thoiGianHenTra && this.form.thoiGianHenTra[1]),
      }
    },
    resetForm() {
      this.form = {
        dataLinhVucId: [],
        thoiGianTiepNhan: null,
        thoiGianHenTra: null,
      }
    },
  },
}
</script>
