<template>
  <b-modal
    id="bienNhanHoSo"
    :hide-footer="true"
    :hide-header="true"
    :no-close-on-backdrop="true"
    size="lg"
  >
    <div ref="docBody">
      <div class="lien-1">
        <table style="width: 100%">
          <tr>
            <td style="width: 50%; text-transform: uppercase">
              <div style="text-align: center; padding-top: 15px">
                {{ detailData.tenBoPhanTiepNhan }}
              </div>
              <div style="text-align: center; font-weight: bold">
                Bộ phận tiếp nhận và <br>
                trả kết quả
                <p style="width: 18%; margin-left: 41%; border-bottom: 1px solid;" />
              </div>
            </td>
            <td style="width: 50%; text-transform: uppercase">
              <div style="text-align: center; font-weight: bold; text-transform: uppercase">
                Cộng hoà xã hội chủ nghĩa việt nam
              </div>
              <div style="text-align: center; font-weight: bold">
                <span style="padding-bottom: 2px; border-bottom: 1px solid;">
                  Độc lập - Tự do - Hạnh phúc
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td style="width: 50%; text-transform: uppercase">
              <div style="text-align: center;">
                Số:<span class="ml-1">/SKTH</span>
              </div>
            </td>
            <td style="text-align: center;">
              <em>
                {{ formatDate(detailData.ngayNop) }}
              </em>
            </td>
          </tr>
          <tr>
            <td style="text-align: center;">
              <b-img
                :src="`data:image/png;base64, ${detailData.barCodeBase64}`"
                alt="Seri image"
                style="width: 250px; height: 50px;"
              />
            </td>
            <td style="text-align: center;">
              <table>
                <tr style="width: 100%;">
                  <td style="text-align: center; width: 60%;">
                    Mã QR dùng để tra cứu tiến độ xử lý hồ sơ qua Zalo
                  </td>
                  <td style="width: 40%;">
                    <img
                      :src="`data:image/png;base64, ${detailData.qrCodeBase64}`"
                      alt="QR code"
                      style="width: 100px; height: 100px;"
                    >
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <div style="text-align: center;">
          <p style="font-size: 14px;margin-bottom: 0; font-weight: bold; margin-top: 1.5rem">
            GIẤY TIẾP NHẬN HỒ SƠ VÀ HẸN TRẢ KẾT QUẢ
          </p>
          <p>
            <em style="display: block;">(Liên 1: Lưu lại bộ phận tiếp nhận và trả kết quả)</em>
          </p>
        </div>
        <div
          class="fw-bold doc-content"
          style="font-weight: bold;"
        >
          <p style="margin-bottom: 3px; font-weight: bold;">
            Bộ phận tiếp nhận và trả kết quả: {{ detailData.tenBoPhanTiepNhan }} - SĐT:
          </p>
          <p style="margin-bottom: 3px; font-weight: bold;">
            Tiếp nhận hồ sơ của: {{ detailData.chuHoSo_HoVaTen }} (Người nộp hồ sơ: {{ detailData.nguoiNop_HoVaTen }})
          </p>
          <p style="margin-bottom: 3px;">
            Địa chỉ chủ hồ sơ: <span style="font-weight: 300;">{{ detailData.chuHoSo_DiaChiDayDu }}</span>
          </p>
          <p style="margin-bottom: 3px;" />
          <p style="margin-bottom: 3px;">
            Địa chỉ người nộp: <span style="font-weight: 300;">{{ detailData.nguoiNop_DiaChiDayDu }}</span>
          </p>
          <p style="margin-bottom: 3px;" />
          <p style="margin-bottom: 3px; display: inline;">
            Số điện thoại chủ hồ sơ:
            <span style="font-weight: 300;">{{ detailData.chuHoSo_SoDienThoai }}</span>
          </p>
          <p style="margin-bottom: 3px; display: inline; margin-left: 1.5rem;">
            Email chủ hồ sơ: <span style="font-weight: 300;">{{ detailData.chuHoSo_DiaChiEmail }}</span>
          </p>
          <p style="margin-bottom: 3px;" />
          <p style="margin-bottom: 3px; display: inline;">
            Số điện thoại người nộp:
            <span style="font-weight: 300;">{{ detailData.nguoiNop_SoDienThoai }}</span>
          </p>
          <p
            style="margin-bottom: 3px; display: inline; margin-left: 1.5rem;"
          >
            Email người nộp: <span style="font-weight: 300;">{{ detailData.nguoiNop_DiaChiEmail }}</span>
          </p>
          <p style="margin-bottom: 3px;">
            Nội dung yêu cầu giải quyết: {{ detailData.tenThuTucHanhChinh }}
          </p>
          <p style="margin-bottom: 3px;">
            1. Thành phần hồ sơ: <span v-if="!(detailData.hoSoGiayToModels && detailData.hoSoGiayToModels.length > 0)">Không có</span>
          </p>
          <div v-if="detailData.hoSoGiayToModels && detailData.hoSoGiayToModels.length > 0">
            <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
              <tr>
                <th style="border: 1px solid black; padding: 10px; width: 35px !important; text-align: center;">
                  STT
                </th>
                <th style="border: 1px solid black; padding: 10px; text-align: center;">
                  Tên giấy tờ
                </th>
                <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                  Bản chính
                </th>
                <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                  Bản sao
                </th>
                <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                  Bản photo
                </th>
              </tr>
              <tr
                v-for="(giayTo, index) in detailData.hoSoGiayToModels"
                :key="index"
              >
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ index + 1 }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px;">
                  <span style="font-weight: 300;">{{ giayTo.tenGiayTo }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ giayTo.banChinh }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ giayTo.banSao }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ giayTo.banPhoto }}</span>
                </td>
              </tr>
            </table>
          </div>
          <p style="margin-bottom: 3px;">
            2. Giấy tờ khác: {{ detailData.hoSoGiayToKhacModels && detailData.hoSoGiayToKhacModels.length > 0 ? detailData.hoSoGiayToKhacModels[0].tenGiayTo : 'Không có' }}
            <!-- <table
              v-if="detailData.hoSoGiayToKhacModels && detailData.hoSoGiayToKhacModels.length > 0"
              style="width: 100%; border-collapse: collapse; border: 1px solid black;"
            >
              <tr>
                <th style="border: 1px solid black; padding: 10px; width: 35px !important; text-align: center;">
                  STT
                </th>
                <th style="border: 1px solid black; padding: 10px; text-align: center;">
                  Tên giấy tờ
                </th>
                <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                  Bản chính
                </th>
                <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                  Bản sao
                </th>
                <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                  Bản photo
                </th>
              </tr>
              <tr
                v-for="(giayTo, index) in detailData.hoSoGiayToKhacModels"
                :key="index"
              >
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ index + 1 }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px;">
                  <span style="font-weight: 300;">{{ giayTo.tenGiayTo }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ giayTo.banChinh }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ giayTo.banSao }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ giayTo.banPhoto }}</span>
                </td>
              </tr>
            </table>
            <span v-else>Không có</span> -->
          </p>
          <p style="margin-bottom: 3px;">
            3. Số lượng hồ sơ:
            <span>{{ detailData.soBoHoSo }} (bộ)</span>
          </p>
          <p style="margin-bottom: 3px;">
            4. Thời gian giải quyết hồ sơ theo qui định:
            <span>{{ detailData.soNgayXuLy }} ngày làm việc</span>
          </p>
          <p style="margin-bottom: 3px;">
            5. Thời gian nhận hồ sơ:
            <span>{{ formatDateTime(detailData.ngayNop) }}</span>
          </p>
          <p style="margin-bottom: 3px;">
            6. Thời gian trả kết quả giải quyết hồ sơ:
            <span>{{ formatDateTime(detailData.ngayHenTraKq) }}</span>
          </p>
          <p style="margin-bottom: 3px;">
            7. Địa chỉ đăng ký nhận kết quả: Bộ phận tiếp nhận và trả kết quả
            <span />
          </p>
          <p style="margin-bottom: 3px;">
            Chú ý: Cá nhân, tổ chức đến nhận kết quả phải mang theo giấy tiếp nhận hồ sơ và trả kết quả, chứng minh thư nhân dân và giấy ủy quyền
            (đối với trường hợp không phải chính chủ đến nhận kết quả)
          </p>
          <p style="margin-bottom: 3px;">
            Để tra cứu thông tin hồ sơ, công dân đưa vào mã quét mã vạch hoặc quét mã QR code trên ứng dụng zalo
          </p>
          <p style="margin-bottom: 3px;">
            Website: http://dichvucong.mpi.gov.vn
          </p>
          <br><br>
          <table style="width: 100%">
            <tr>
              <td style="width: 50%; text-align: center;">
                <p style="margin-bottom: 3px; font-weight: bold; text-transform: uppercase; font-size: 14px;">
                  Người nộp hồ sơ
                </p>
                <em style="font-weight: 400">(Ký và ghi rõ họ tên)</em>
                <br><br><br><br>
                <span style="font-weight: 300;">{{ detailData.nguoiNop_HoVaTen }}</span>
              </td>
              <td style="width: 50%; text-align: center;">
                <p style="margin-bottom: 3px; font-weight: bold; text-transform: uppercase; font-size: 14px">
                  Người tiếp nhận hồ sơ
                </p>
                <em style="font-weight: 400">(Ký và ghi rõ họ tên)</em>
                <br><br><br><br>
                <span style="font-weight: 300;">{{ user && user.hoTen }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div style="page-break-after: always;" />
      <div>&nbsp;</div>
      <br
        clear="all"
        style="mso-special-character:line-break;page-break-before:always"
      >
      <div class="lien-2">
        <table style="width: 100%">
          <tr>
            <td style="width: 50%; text-transform: uppercase">
              <div style="text-align: center; padding-top: 15px">
                {{ detailData.tenBoPhanTiepNhan }}
              </div>
              <div style="text-align: center; font-weight: bold">
                Bộ phận tiếp nhận và <br>
                trả kết quả
                <p style="width: 18%; margin-left: 41%; border-bottom: 1px solid;" />
              </div>
            </td>
            <td style="width: 50%; text-transform: uppercase">
              <div style="text-align: center; font-weight: bold; text-transform: uppercase">
                Cộng hoà xã hội chủ nghĩa việt nam
              </div>
              <div style="text-align: center; font-weight: bold">
                <span style="padding-bottom: 2px; border-bottom: 1px solid;">
                  Độc lập - Tự do - Hạnh phúc
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td style="width: 50%; text-transform: uppercase">
              <div style="text-align: center;">
                Số:<span class="ml-1">/SKTH</span>
              </div>
            </td>
            <td style="text-align: center;">
              <em>
                {{ formatDate(detailData.ngayNop) }}
              </em>
            </td>
          </tr>
          <tr>
            <td style="text-align: center;">
              <b-img
                :src="`data:image/png;base64, ${detailData.barCodeBase64}`"
                alt="Seri image"
                style="width: 250px; height: 50px;"
              />
            </td>
            <td style="text-align: center; display: flex; justify-content: between;">
              <table>
                <tr style="width: 100%;">
                  <td style="text-align: center; width: 60%;">
                    Mã QR dùng để tra cứu tiến độ xử lý hồ sơ qua Zalo
                  </td>
                  <td style="width: 40%;">
                    <img
                      :src="`data:image/png;base64, ${detailData.qrCodeBase64}`"
                      alt="QR code"
                      style="width: 100px; height: 100px;"
                    >
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <div style="text-align: center;">
          <p style="font-size: 14px;margin-bottom: 0; font-weight: bold; margin-top: 1.5rem">
            GIẤY TIẾP NHẬN HỒ SƠ VÀ HẸN TRẢ KẾT QUẢ
          </p>
          <p>
            <em style="display: block;">(Liên 2: Giao cho công dân, doanh nghiệp)</em>
          </p>
        </div>
        <div
          class="fw-bold doc-content"
          style="font-weight: bold;"
        >
          <p style="margin-bottom: 3px; font-weight: bold;">
            Bộ phận tiếp nhận và trả kết quả: {{ detailData.tenBoPhanTiepNhan }} - SĐT:
          </p>
          <p style="margin-bottom: 3px; font-weight: bold;">
            Tiếp nhận hồ sơ của: {{ detailData.chuHoSo_HoVaTen }} (Người nộp hồ sơ: {{ detailData.nguoiNop_HoVaTen }})
          </p>
          <p style="margin-bottom: 3px;">
            Địa chỉ chủ hồ sơ: <span style="font-weight: 300;">{{ detailData.chuHoSo_DiaChiDayDu }}</span>
          </p>
          <p style="margin-bottom: 3px;" />
          <p style="margin-bottom: 3px;">
            Địa chỉ người nộp: <span style="font-weight: 300;">{{ detailData.nguoiNop_DiaChiDayDu }}</span>
          </p>
          <p style="margin-bottom: 3px;" />
          <p style="margin-bottom: 3px; display: inline;">
            Số điện thoại chủ hồ sơ:
            <span style="font-weight: 300;">{{ detailData.chuHoSo_SoDienThoai }}</span>
          </p>
          <p style="margin-bottom: 3px; display: inline; margin-left: 1.5rem;">
            Email chủ hồ sơ: <span style="font-weight: 300;">{{ detailData.chuHoSo_DiaChiEmail }}</span>
          </p>
          <p style="margin-bottom: 3px;" />
          <p style="margin-bottom: 3px; display: inline;">
            Số điện thoại người nộp:
            <span style="font-weight: 300;">{{ detailData.nguoiNop_SoDienThoai }}</span>
          </p>
          <p
            style="margin-bottom: 3px; display: inline; margin-left: 1.5rem;"
          >
            Email người nộp: <span style="font-weight: 300;">{{ detailData.nguoiNop_DiaChiEmail }}</span>
          </p>
          <p style="margin-bottom: 3px;">
            Nội dung yêu cầu giải quyết: {{ detailData.tenThuTucHanhChinh }}
          </p>
          <p style="margin-bottom: 3px;">
            1. Thành phần hồ sơ: <span v-if="!(detailData.hoSoGiayToModels && detailData.hoSoGiayToModels.length > 0)">Không có</span>
          </p>
          <div v-if="detailData.hoSoGiayToModels && detailData.hoSoGiayToModels.length > 0">
            <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
              <tr>
                <th style="border: 1px solid black; padding: 10px; width: 35px !important; text-align: center;">
                  STT
                </th>
                <th style="border: 1px solid black; padding: 10px; text-align: center;">
                  Tên giấy tờ
                </th>
                <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                  Bản chính
                </th>
                <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                  Bản sao
                </th>
                <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                  Bản photo
                </th>
              </tr>
              <tr
                v-for="(giayTo, index) in detailData.hoSoGiayToModels"
                :key="index"
              >
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ index + 1 }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px;">
                  <span style="font-weight: 300;">{{ giayTo.tenGiayTo }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ giayTo.banChinh }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ giayTo.banSao }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ giayTo.banPhoto }}</span>
                </td>
              </tr>
            </table>
          </div>
          <p style="margin-bottom: 3px;">
            2. Giấy tờ khác: {{ detailData.hoSoGiayToKhacModels && detailData.hoSoGiayToKhacModels.length > 0 ? detailData.hoSoGiayToKhacModels[0].tenGiayTo : 'Không có' }}
            <!-- <table
              v-if="detailData.hoSoGiayToKhacModels && detailData.hoSoGiayToKhacModels.length > 0"
              style="width: 100%; border-collapse: collapse; border: 1px solid black;"
            >
              <tr>
                <th style="border: 1px solid black; padding: 10px; width: 35px !important; text-align: center;">
                  STT
                </th>
                <th style="border: 1px solid black; padding: 10px; text-align: center;">
                  Tên giấy tờ
                </th>
                <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                  Bản chính
                </th>
                <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                  Bản sao
                </th>
                <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                  Bản photo
                </th>
              </tr>
              <tr
                v-for="(giayTo, index) in detailData.hoSoGiayToKhacModels"
                :key="index"
              >
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ index + 1 }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px;">
                  <span style="font-weight: 300;">{{ giayTo.tenGiayTo }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ giayTo.banChinh }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ giayTo.banSao }}</span>
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  <span style="font-weight: 300;">{{ giayTo.banPhoto }}</span>
                </td>
              </tr>
            </table>
            <span v-else>Không có</span> -->
          </p>
          <p style="margin-bottom: 3px;">
            3. Số lượng hồ sơ:
            <span>{{ detailData.soBoHoSo }} (bộ)</span>
          </p>
          <p style="margin-bottom: 3px;">
            4. Thời gian giải quyết hồ sơ theo qui định:
            <span>{{ detailData.soNgayXuLy }} ngày làm việc</span>
          </p>
          <p style="margin-bottom: 3px;">
            5. Thời gian nhận hồ sơ:
            <span>{{ formatDateTime(detailData.ngayNop) }}</span>
          </p>
          <p style="margin-bottom: 3px;">
            6. Thời gian trả kết quả giải quyết hồ sơ:
            <span>{{ formatDateTime(detailData.ngayHenTraKq) }}</span>
          </p>
          <p style="margin-bottom: 3px;">
            7. Địa chỉ đăng ký nhận kết quả: Bộ phận tiếp nhận và trả kết quả
            <span />
          </p>
          <p style="margin-bottom: 3px;">
            Chú ý: Cá nhân, tổ chức đến nhận kết quả phải mang theo giấy tiếp nhận hồ sơ và trả kết quả, chứng minh thư nhân dân và giấy ủy quyền
            (đối với trường hợp không phải chính chủ đến nhận kết quả)
          </p>
          <p style="margin-bottom: 3px;">
            Để tra cứu thông tin hồ sơ, công dân đưa vào mã quét mã vạch hoặc quét mã QR code trên ứng dụng zalo
          </p>
          <p style="margin-bottom: 3px;">
            Website: http://dichvucong.mpi.gov.vn
          </p>
          <br><br>
          <table style="width: 100%">
            <tr>
              <td style="width: 50%; text-align: center;">
                <p style="margin-bottom: 3px; font-weight: bold; text-transform: uppercase; font-size: 14px;">
                  Người nộp hồ sơ
                </p>
                <em style="font-weight: 400">(Ký và ghi rõ họ tên)</em>
                <br><br><br><br>
                <span style="font-weight: 300;">{{ detailData.nguoiNop_HoVaTen }}</span>
              </td>
              <td style="width: 50%; text-align: center;">
                <p style="margin-bottom: 3px; font-weight: bold; text-transform: uppercase; font-size: 14px">
                  Người tiếp nhận hồ sơ
                </p>
                <em style="font-weight: 400">(Ký và ghi rõ họ tên)</em>
                <br><br><br><br>
                <span style="font-weight: 300;">{{ user && user.hoTen }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div style="text-align: right; margin-top: 1.5rem">
      <b-button
        style="margin-right: 1rem"
        variant="primary"
        size="sm"
        @click="onClickToPrint"
      >
        In biên nhận
      </b-button>
      <b-button
        style="margin-right: 1rem"
        variant="primary"
        size="sm"
        @click="onExportFileWord"
      >
        Kết xuất
      </b-button>
      <b-button
        variant="danger"
        size="sm"
        @click="hide"
      >
        Đóng
      </b-button>
    </div>
    <iframe
      id="printing-frame"
      name="print_frame"
      style="display:none;"
    />
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BImg,
} from 'bootstrap-vue'
import END_POINTS from '@/api/endpoints'

export default {
  name: 'BienNhanHoSo',
  components: {
    BButton,
    BModal,
    BImg,
  },
  data() {
    return {
      columns: [
        {
          field: '',
          key: 'a',
          title: 'STT',
          width: 50,
          align: 'center',
          renderBodyCell: ({ rowIndex }) => rowIndex + 1,
        },
        {
          field: 'tenGiayTo',
          key: 'b',
          title: 'Tên giấy tờ',
          width: 100,
        },
        {
          field: 'banChinh',
          key: 'c',
          title: 'Bản chính',
          width: 100,
        },
        {
          field: 'banSao',
          key: 'd',
          title: 'Bản sao',
          width: 100,
        },
        {
          field: 'banPhoto',
          key: 'e',
          title: 'Bản photo',
          width: 100,
        },
        {
          field: 'file',
          key: 'f',
          title: 'File đính kèm',
          width: 100,
          renderBodyCell: ({ rowIndex, row }) => <div class="upload-btn-wrapper">
            <span>
              {this.getFileName(rowIndex, row)}
            </span>
          </div>,
        },
      ],
      detailData: {},
      user: null,
    }
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('userInfo'))
  },
  methods: {
    show(data) {
      const id = data.data?.id || data.id
      this.layChiTietHoSo(id)
      this.$bvModal.show('bienNhanHoSo')
    },
    hide() {
      this.$bvModal.hide('bienNhanHoSo')
      this.$emit('close-page')
    },
    onClickToPrint() {
      window.frames.print_frame.document.title = document.title
      window.frames.print_frame.document.body.innerHTML = `<!DOCTYPE html>
      <html>
        <title> In biên nhận hồ sơ </title>
        <body>
          ${this.$refs.docBody.innerHTML}
        </body>
      </html>`
      window.frames.print_frame.window.focus()
      window.frames.print_frame.window.print()
    },
    onExportFileWord() {
      const preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>"
      const postHtml = '</body></html>'
      const printEle = this.$refs.docBody.innerHTML
      const html = preHtml + printEle + postHtml
      const blob = new Blob(['\ufeff', html], {
        type: 'application/msword',
      })
      // Specify link url
      const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(html)}`
      // Specify file name
      const filename = 'giao-nhan-ho-so.doc'
      // Create download link element
      const downloadLink = document.createElement('a')
      document.body.appendChild(downloadLink)
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        // Create a link to the file
        downloadLink.href = url
        // Setting the file name
        downloadLink.download = filename
        // triggering the function
        downloadLink.click()
      }
      document.body.removeChild(downloadLink)
    },
    layChiTietHoSo(id) {
      this.$axios.get(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.CHI_TIET, { id }).then(response => {
        if (response.data && response.data.succeeded) {
          this.detailData = response.data.data
        }
      })
    },
    formatDate(date) {
      let newDate = new Date()
      if (date) {
        newDate = new Date(date)
      }
      return `ngày ${newDate.getDate()} tháng ${newDate.getMonth() + 1} năm ${newDate.getFullYear()}`
    },
    formatDateTime(date) {
      let newDate = new Date()
      if (date) {
        newDate = new Date(date)
      }
      return `${newDate.getHours()} giờ ${newDate.getMinutes()} phút, ngày ${newDate.getDate()} tháng ${newDate.getMonth() + 1} năm ${newDate.getFullYear()}`
    },
    getFileName(rowIndex, row) {
      if (row.file) {
        return <div>
          {row.file.map((file, id) => <div key={id}>
            {file.name || file.tenFileDinhKem}
          </div>)}
        </div>
      }
      return ''
    },
  },
}
</script>

<style scoped>

</style>
